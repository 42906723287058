<template>
    <Toast />
    <form @submit.prevent="submit()">
        <div class="p-d-flex p-jc-between">
            <p class="title">Account Management</p>
            <div>
                <Button label="Add" class="button btn" type="submit" />
            </div>
        </div>

        <div class="card">
            <h4>Add Account</h4>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Name</p>
                    <InputText type="text" v-model="v$.Name.$model" placeholder="name"></InputText>
                    <p v-for="error of v$.Name.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a first name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Account code</p>
                    <InputText type="text" v-model="v$.accountCode.$model" placeholder="account code"></InputText>
                    <p v-for="error of v$.accountCode.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a last name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Quickbooks ID</p>
                    <InputNumber type="text" v-model="v$.quickbooksID.$model" placeholder="quickbooksID"></InputNumber>
                    <p v-for="error of v$.quickbooksID.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a  quickbooksID' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Description</p>
                    <InputText type="text" v-model="v$.description.$model" placeholder="description"></InputText>
                    <p v-for="error of v$.description.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter decription' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Description</p>
                    <div class="radio-container">
                        <div class="field-radiobutton">
                            <RadioButton id="partner" name="partner" value="true" v-model="partner" />
                            <label class="radioLabel" for="city1">Partner</label>
                        </div>
                        <div class="field-radiobutton">
                            <RadioButton id="member" name="member" value="false" v-model="partner" />
                            <label class="radioLabel" for="member">Member</label>
                        </div>
                    </div>
                </div>
                <div class="p-col-0 p-md-6"></div>
                <div v-if="partner === 'true'" class="p-col-12 p-md-6">
                    <div class="field-title">Channels and Contract Templates</div>

                    <!-- Table structure for channels and contract templates -->
                    <table class="channels-table">
                        <thead>
                            <tr>
                                <th>Enable</th>
                                <th>Channel Name</th>
                                <th>Contract Template</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="channel in channels" :key="channel.subnetwork_id">
                                <!-- Enable Switch Column -->
                                <td class="switch-column">
                                    <InputSwitch v-model="channel.enabled" @change="toggleChannel(channel)" />
                                </td>

                                <!-- Channel Name Column -->
                                <td class="channel-name-column">
                                    {{ channel.name }}
                                </td>

                                <!-- Contract Template Column -->
                                <!-- :disabled="!channel.enabled || channel.subnetwork_id === 'f4cd7264-3f00-463b-b2e9-d9e69c3ec193'" -->
                                <td class="template-column">
                                  <select 
                                        v-model="channel.selectedTemplate"
                                        disabled
                                        class="template-dropdown"
                                        @click="checkTemplate(channel)"
                                    >
                                    
                                        <option value="self"> {{channel.isContractTemplate?`${channel?.name} [Template Available]`:`${channel?.name} [Template Unavailable]` }}</option>
                                        <option value="default">Default [ArenaCX]</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import AccountMangementService from '../service/AccountManagementService';
import { required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    data() {
        return {
            Name: '',
            accountCode: '',
            quickbooksID: '',
            description: '',
            partner: null,
            channels: [],
        };
    },
    validations() {
        return {
            Name: { required, minLength: minLength(1) },
            accountCode: { required, minLength: minLength(1) },
            quickbooksID: { required, minLength: minLength(1) },
            description: { required, minLength: minLength(1) },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.accountMangementService = new AccountMangementService();
        this.fetchChannels(); // Call the fetch method on creation
    },
    methods: {
       async checkTemplate(ch) {
                ch.isContractTemplate= (await this.accountMangementService.getContractTemplate(ch.subnetwork_id)).data.data.templateExists
        },
        toggleChannel(ch) {
          
        },
        async fetchChannels() {
            try {
                const response = await this.accountMangementService.getChannels(); 
                if (response.status === 200) {
                    const defaultSubnetworkId = 'f4cd7264-3f00-463b-b2e9-d9e69c3ec193';  
                    this.channels = response?.data.data.map((channel) => {
                       

                        return { ...channel, enabled: true, selectedTemplate: 'default'};
                    })
                    .sort((a, b) => {
                        if (a.subnetwork_id === defaultSubnetworkId) {
                            return -1;
                        }
                        return 1;
                    });

                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error fetching channels',
                        detail: response.data,
                        life: 3000,
                    });
                }
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error fetching channels',
                    detail: error.message,
                    life: 3000,
                });
            }
        },
        submit() {
            this.v$.$touch();
            this.addUser();
        },
        async addUser() {
            var payload = {
                quickbooksID: this.quickbooksID,
                accountCode: this.accountCode,
                name: this.Name,
                description: this.description,
                isPartner: this.partner === 'true' ? true : false,
                channels: this.channels
                    .filter((ch) => ch.enabled)
                    .map((channel) => {
                        return {
                            subnetworkId: channel.subnetwork_id,
                            contractSubnetworkId:
                                channel.selectedTemplate === 'default'
                                    ? 'f4cd7264-3f00-463b-b2e9-d9e69c3ec193'
                                    : channel.subnetwork_id,
                        };
                    }),
            };
            var userResponse = await this.accountMangementService.addNewUser(payload);
            if (userResponse.status == 200) {
                this.$toast.add({ severity: 'success', summary: userResponse.data, detail: '', life: 3000 });
                (this.Name = ''),
                    (this.accountCode = ''),
                    (this.description = ''),
                    (this.quickbooksID = ''),
                    (this.partner = null),
                    (this.channels = this.channels.map((channel) => {
                        channel.enabled = false;
                        channel.selectedTemplate = 'default';
                        return channel;
                    }));
                this.v$.$reset();
                return;
            }
            this.$toast.add({
                severity: 'error',
                summary: 'Error while creating user',
                detail: userResponse.data,
                life: 3000,
            });
        },
    },
};
</script>

<style scoped>
:deep(.btn) {
    width: 8rem !important;
}

:deep(.card) {
    margin-top: 1.5rem;
}

.subtitle {
    font-size: 1rem;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}

.radio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 15rem;
    margin-left: 1rem;
}

.radioLabel {
    margin-left: 1rem;
}
::v-deep(.p-inputtext) {
    margin-left: 1rem;
    max-width: 30rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
/* Table styles */
.channels-table {
    width: 100%;
    border-collapse: collapse;
    margin-left: 10px;
    margin-top: 10px;
}

.channels-table th,
.channels-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

/* Column styles */
.switch-column {
    width: 10%;
}

.channel-name-column {
    width: 40%;
    font-size: 14px;
}

.template-column {
    width: 50%;
}

/* Dropdown styles */
/* Ensure the dropdown fills the cell */
.template-dropdown {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-sizing: border-box;
}

/* Style when dropdown is focused (active state) */
.template-dropdown:focus {
    outline: none;
    border-color: #007bff; /* Add blue border on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle blue glow effect */
    background-color: white; /* Keep background white on focus */
    color: black; /* Ensure text color is black */
}

/* Change the caret (dropdown arrow) color if needed */
.template-dropdown::-ms-expand {
    color: black;
}

/* Optional - Style hover and active states for better UX */
.template-dropdown:hover {
    border-color: #007bff; /* Blue border on hover */
}

.template-dropdown:active {
    border-color: #007bff;
}

.channels-table th {
    font-weight: 400;
}

.template-dropdown option {
    border-radius: 0px; /* Remove any border-radius on the expanded options */
    background-color: white;
    color: black;
}

/* Customize the dropdown open list */
.template-dropdown:focus option {
    border-radius: 0px; /* Ensure no rounded corners for the dropdown list */
    background-color: white;
    color: black;
}
</style>
